<template>
  <div>
    <div class="app-content-box bg-gray">
      <div class="container mt-4">
        <div class="text-center book-heading-big">Gratulacje! <br/> Plan czytelniczy został ukończony.</div>
          <router-link :to="{name: 'TrainingPlanHistory'}"><button class="btn-black-border w-100 mt-3 mb-1" >Zobacz podsumowanie</button></router-link>
          <router-link to="/wybierz-plan"><button class="btn-black-full w-100 mt-3 mb-1">Załóż nowy plan</button></router-link>
          <router-link to="/"><button class="btn-black-border w-100 mt-3 mb-1" >Zakończ</button></router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TrainingPlanSuccess'
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
  .book-heading-big {
    line-height: 30px;
  }
</style>
